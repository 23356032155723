<template>
  <!--begin::Legal Cookies ES-->
  <div class="p-6">
    <div class="text-center h1-primary mt-3 mb-6">
      Política de cookies
      <div class="tertiary-rectangle" style="margin: 0.25em auto;"></div>
    </div>

    <div class="paragraph-primary">
      La página web de LOGIOK informa acerca del uso de cookies en su portal.
    </div>
      
    <div class="paragraph-primary mt-3">
      Las cookies son una herramienta que permite al prestador del servicio almacenar y recuperar 
      datos e información de los hábitos de navegación de los usuarios para utilizarlos 
      con distintas funcionalidades.
    </div>

    <div class="text-center h3-primary mt-9 mb-6">
      Tipos de cookies
    </div>
      
    <div class="paragraph-primary">
      Dentro de las políticas de cookies podemos encontrar varias clasificaciones 
      en función de quien las gestione, su funcionalidad y el plazo de tiempo que permanecen activadas.   
    </div>
      
    <div class="paragraph-primary mt-3">
      En función de quién sea la entidad que gestione el dominio y la información obtenida 
      se distingue entre cookies propias y cookies de terceros.
    </div>
      
    <div class="paragraph-primary mt-3">
      La segunda clasificación depende de la finalidad para la que sean utilizados los datos obtenidos, 
      encontrando los siguientes tipos: cookies técnicas, cookies de personalización o preferencias, 
      cookies de análisis y cookies de publicidad comportamental.
    </div>
      
    <div class="paragraph-primary mt-3">
      Finalmente, hay una última clasificación en función del plazo de tiempo en que estas cookies permanezcan 
      activadas distinguiendo entre cookies de sesión y cookies persistentes.
    </div>
      
    <div class="paragraph-primary mt-3">
      Para una información más detallada a este respecto consulte la
      <a
        href="https://www.aepd.es/sites/default/files/2020-07/guia-cookies.pdf"
        target="_blank"
      >
        <strong><u>Guía sobre el uso de las cookies de la AEPD</u></strong>
      </a>.
    </div>
      
    <div class="text-center h3-primary mt-9 mb-6">
      Cookies utilizadas en la web
    </div>
      
    <div class="paragraph-primary">
      A continuación, se identifican las cookies que utiliza esta página web 
      teniendo en cuenta las diferentes tipologías establecidas en el apartado anterior.   
    </div>

    <v-simple-table class="my-6" id="CookiesTable">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-primary">
              Cookie
            </th>
            <th class="text-primary">
              Tipo según tiempo
            </th>
            <th class="text-primary">
              Tipo según finalidad
            </th>
            <th class="text-primary">
              Tipo según quien las gestione
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              logiok_session
            </td>
            <td>
              Sesión
            </td>
            <td>
              Técnica, requerida: ofrecer las distintas opciones de la web disponibles para tu usuario.
            </td>
            <td>
              Propia
            </td>
          </tr>
          <tr>
            <td>
              XSRF-TOKEN
            </td>
            <td>
              Sesión
            </td>
            <td>
              Técnica, requerida: seguridad en los envíos de formularios.
            </td>
            <td>
              Propia
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    
    <div class="text-center h3-primary mt-9 mb-6">
      Modificación de la configuración de cookies en función del navegador
    </div>
    
    <div class="paragraph-primary">
      Cada usuario puede configurar y rechazar las cookies de la página web de LogiOK 
      o de cualquier otra página web a través de su navegador. 
      En cada navegador el procedimiento es diferente.
      Podrás obtener más información al respecto en las siguientes páginas clasificadas según el navegador.
    </div>
    
    <ul class="ml-9 my-6">
      <li>
        Microsoft Internet Explorer: 
        <a
          href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
          target="_blank"
        >
          <strong><u>
            support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies
          </u></strong>
        </a>
      </li>
      <li>
        Microsoft Edge: 
        <a
          href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
          target="_blank"
        >
          <strong><u>
            support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge
          </u></strong>
        </a>
      </li>
      <li>
        FireFox:
        <a
          href="https://support.mozilla.org/es/kb/Borrar%20cookies"
          target="_blank"
        >
          <strong><u>
            support.mozilla.org/es/kb/Borrar%20cookies
          </u></strong>
        </a>
      </li>
      <li>
        Chrome:
        <a
          href="https://support.google.com/chrome/answer/95647?hl=es"
          target="_blank"
        >
          <strong><u>
            support.google.com/chrome/answer/95647
          </u></strong>
        </a>
      </li>
      <li>
        Safari:
        <a
          href="https://www.apple.com/es/privacy/use-of-cookies"
          target="_blank"
        >
          <strong><u>
            www.apple.com/es/privacy/use-of-cookies
          </u></strong>
        </a>
      </li>
    </ul>
  </div>
  <!--end::Legal Cookies ES-->
</template>

<style scoped>
#CookiesTable th, #CookiesTable td {
  font-size: 1em;
}
</style>

<script>
export default {
  name: "LegalCookiesES"
};
</script>
