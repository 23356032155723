<template>
  <!--begin::Legal Cookies-->
  <div>
    <LegalCookiesEN v-if="UserLang === 'en'" />
    <LegalCookiesES v-else />
  </div>
  <!--end::Legal Cookies-->
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

import LegalCookiesEN from "@/view/pages/landing/legal/cookies/Content_en.vue";
import LegalCookiesES from "@/view/pages/landing/legal/cookies/Content_es.vue";

export default {
  name: "LegalCookies",

  components: {
    LegalCookiesEN,
    LegalCookiesES
  },

  data() {
    return {
      UserLang: i18nService.getActiveLanguage()
    };
  },

  mounted() {
    this.$nextTick(() => {
      document.title = this.$t("HEADER.TITLE");
    });
  }
};
</script>
